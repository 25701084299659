import axios from 'axios';
import api from '../../services/api';
let tokenSource;

export const fetchData = async (product_id, quantity) => {

    try {
        if (typeof tokenSource !== typeof undefined) {
            tokenSource.cancel('Operation canceled due to new request.');
        }
        // save the new request for cancellation
        tokenSource = axios.CancelToken.source();

        const { data } = await api.post(`${process.env.GATSBY_API_URL}/carts/set-product`, { product_id, quantity }, {
            withCredentials: true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            cancelToken: tokenSource.token,
        });

        return { result: data };

    } catch (err) {
        if (axios.isCancel(err)) return { cancelPrevQuery: true };
        //return [err];
        return { error: err };
    }
};