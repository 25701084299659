import { types } from "../actions/types";

const initialState = {
    isLoggedIn: false,
    loading: true,
    user: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.LOGIN:
            return { ...state, isLoggedIn: true, user: action.user, loading: false };
        case types.LOGOUT:
            return { ...state, isLoggedIn: false, user: null, loading: false };
        case types.LOGIN_LOADING:
            return { ...state, isLoggedIn: false, loading: true };
        default:
            return state;
    }
};