export const types = {
    SAMPLE_TYPE: "SAMPLE_TYPE",
    OPEN_CART: "OPEN_CART",
    CLOSE_CART: "CLOSE_CART",
    OPEN_PREVIEW: "OPEN_PREVIEW",
    CLOSE_PREVIEW: "CLOSE_PREVIEW",
    LOADING_PREVIEW: "LOADING_PREVIEW",
    ERROR_PREVIEW: "ERROR_PREVIEW",
    SUCCESS_PREVIEW: "SUCCESS_PREVIEW",
    LOGIN: "LOGIN",
    LOGOUT: "LOGOUT",
    LOGIN_LOADING: "LOGIN_LOADING"
}