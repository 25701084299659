import React, { useState, useEffect } from 'react';
import { mutate } from 'swr'
import { Link } from 'gatsby'
import api from '../../services/api'
import { ArrowPathIcon, PlusIcon } from '@heroicons/react/24/outline';
import { formatNumber } from '../../utils/formatNumber';

const ProductItemBuscador = (props) => {

    const { product, setOpenSearch } = props
    const [loading, setLoading] = useState(false)
    //const [priceNight, setPriceNight] = useState(null)

    const haddleAddToCart = (id) => {
        setLoading(true)
        api.post(`${process.env.GATSBY_API_URL}/carts/add-product`, {
            product_id: id,
            quantity: 1
        })
            .then(function (response) {
                mutate(`${process.env.GATSBY_API_URL}/carts`)
                setLoading(false)
            })
    }

    /*
    useEffect(() => {
        var fechaActual = new Date()
        var horaActual = fechaActual.getHours()

        if (!(horaActual >= 6 && horaActual < 22)) {
            setPriceNight(product.night_price)
        }

    }, []);
    */


    const pathImage = product.image ? new URL(product.image) : { pathname: '/delicores.png' }

    return (
        <li>
            <div className=''>
                <div className='flex justify-center items-center hover:bg-gray-50  hover:shadow-md p-2 pr-2 w-full h-full'>
                    <Link to={`/${product.slug}`} onClick={() => { setOpenSearch(false) }} className="flex-1 flex flex-row justify-center h-full">
                        <img className={`mr-1 lazyload w-full ${!product.in_stock && "grayscale"}`} style={{ maxWidth: "75px", maxHeight: "75px" }} data-src={`https://d2ja6cy9ij2jx1.cloudfront.net/fit-in/256x256${pathImage.pathname}`} />
                        <div className="flex-1 flex flex-col justify-center pl-2">
                            <div className="text-left overflow-hidden text-xs sm:text-sm font-bold text-gray-900" style={{
                                display: "-webkit-box",
                                "WebkitLineClamp": "2",
                                "WebkitBoxOrient": "vertical",
                                textOverflow: "ellipsis",
                            }}>
                                {product.name}
                            </div>
                            {
                                product.in_stock ?
                                    <>
                                        {/*
                                        <div className="text-left text-sm font-bold">
                                            {"S/ " + (priceNight ? (product.night_price * 1) : (product.price * 1)).toFixed(2)}
                                        </div>
                                        <div className="text-xs font-bold">
                                            {!priceNight && product.compare_at_price &&
                                                <p> Descuento: <span className='ml-1 text-green-500'> {product.compare_at_price && ((-1 * (((priceNight ? product.night_price : product.price) / product.compare_at_price) - 1) * 100).toFixed(0)) + "%"}</span></p>
                                            }

                                        </div>
                                        */}
                                        <div className="text-left text-sm font-bold">
                                            {"S/ " + ((product.price * 1)).toFixed(2)}
                                        </div>
                                        {product.compare_at_price ? <span className="font-thin text-gray-600 text-xs line-through ml-1">{`S/${formatNumber(product.compare_at_price, 2)}`}</span> : null}
                                    </> :
                                    <div className="font-bold text-sm"> Agotado </div>
                            }
                        </div>
                    </Link>
                    {product.in_stock &&
                        <div className="w-11">
                            {!loading ?
                                <button onClick={() => haddleAddToCart(product.id || product._id)} className="hover:bg-green-500 rounded-full hover:text-white p-1 h-full ">
                                    <PlusIcon className='w-8 h-8' />
                                </button>
                                :
                                <button className="hover:bg-green-500 rounded-full hover:text-white p-1  h-full">
                                    <ArrowPathIcon className="w-8 h-8 animate-spin" />
                                </button>
                            }
                        </div>
                    }
                </div>
            </div>
        </li>
    );
}

export default ProductItemBuscador