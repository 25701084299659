exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-complete-js": () => import("./../../../src/pages/complete.js" /* webpackChunkName: "component---src-pages-complete-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-js": () => import("./../../../src/pages/landing.js" /* webpackChunkName: "component---src-pages-landing-js" */),
  "component---src-pages-libro-de-reclamaciones-js": () => import("./../../../src/pages/libro-de-reclamaciones.js" /* webpackChunkName: "component---src-pages-libro-de-reclamaciones-js" */),
  "component---src-pages-licoreria-delivery-js": () => import("./../../../src/pages/licoreria-delivery.js" /* webpackChunkName: "component---src-pages-licoreria-delivery-js" */),
  "component---src-pages-negocios-js": () => import("./../../../src/pages/negocios.js" /* webpackChunkName: "component---src-pages-negocios-js" */),
  "component---src-pages-politicas-de-cambios-y-devoluciones-js": () => import("./../../../src/pages/politicas-de-cambios-y-devoluciones.js" /* webpackChunkName: "component---src-pages-politicas-de-cambios-y-devoluciones-js" */),
  "component---src-pages-regalos-js": () => import("./../../../src/pages/regalos.js" /* webpackChunkName: "component---src-pages-regalos-js" */),
  "component---src-pages-terminos-y-condiciones-js": () => import("./../../../src/pages/terminos-y-condiciones.js" /* webpackChunkName: "component---src-pages-terminos-y-condiciones-js" */),
  "component---src-pages-unsubscribe-email-index-js": () => import("./../../../src/pages/unsubscribe/email/index.js" /* webpackChunkName: "component---src-pages-unsubscribe-email-index-js" */),
  "component---src-templates-brand-js": () => import("./../../../src/templates/Brand.js" /* webpackChunkName: "component---src-templates-brand-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/Category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/Product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

