import React from 'react'
import { ShoppingBagIcon } from '@heroicons/react/24/outline'
import { connect } from "react-redux"
import useSWR from "swr";
import axios from 'axios'
import api from '../../services/api';
/*
const fetcher = (url) => fetch(url, {
    method: 'GET',
    credentials: 'include',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
}).then((res) => res.json());
*/
const fetcher = url => api.post(url).then(res => res.data)

const AppBar = ({ handleOpenCart }) => {
    const { data, error } = useSWR(`${process.env.GATSBY_API_URL}/carts`, fetcher)
    var carrito = 0

    if (error) {
        carrito = "x"
    }

    if (!data) {
        carrito = "..."
    }

    if (data && data.data) {
        data.data.products.forEach(element => {
            carrito = carrito + element.quantity
        });
    }

    return (
        <div className="mx-2 flow-root ">
            <button onClick={() => { handleOpenCart() }} type="button" className="relative rounded-full p-1 text-black  focus:outline-none focus:ring-0 focus:ring-white focus:ring-offset-0 focus:ring-offset-gray-800">
                <span className="sr-only">Cart Icon</span>
                <ShoppingBagIcon className="h-7 w-7 " aria-hidden="true" />
                <span className="absolute top-4 leading-none flex items-center justify-center -right-1 h-[18px] w-[18px] p-0 font-bold text-xs shadow-lg  bg-green-500 border-opacity-50 text-white rounded-full">{carrito}</span>
            </button>
        </div>
    )
}

const mapStateToProps = ({ openCart }) => {
    return { openCart }
}

const mapDispatchToProps = dispatch => {
    return {
        handleOpenCart: () => dispatch({ type: `OPEN_CART` }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppBar)



