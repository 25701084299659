import React from "react"
import ProductItemBuscador from "./ProductItemBuscador";

const ListProductItemBuscador = (props) => {

    const { setOpenSearch } = props
    const dataList = props.products

    return (
        <ul className="space-y-2">
            {dataList.map((item, index) => {
                return <ProductItemBuscador product={item} key={item._id} setOpenSearch={(value) => setOpenSearch(value)} />
            })}
        </ul>
    )

}

export default ListProductItemBuscador
