/* This example requires Tailwind CSS v2.0+ */
import * as React from "react"
import { useState } from "react"
import { Bars3Icon, MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import MenuDrawer from '../MenuDrawer'
import { Link } from 'gatsby'
import Buscador from "../Buscador"
import CartIcon from "../CartIcon"
import ModalStory from '../ModalStory'

const navigation = [
  /*
  {
    name: 'Cervezas',
    href: null,
    sections: [
      {
        name: "Estilos de Cerveza",
        href: null,
        sections: [
          {
            sections: [
              { name: "IPA", href: "IPA" },
              { name: "Hard Seltzer", href: "Hard Seltzer" },
              { name: "Lager", href: "Lager" },
              { name: "Light Beer", href: "Light Beer" },
              { name: "Ale", href: "Ale" },
              { name: "Stout", href: "Stout" },

            ]
          },
          {
            sections: [{ name: "Sour Beer", href: "Sour Beer" },
            { name: "Belgian-Style Ale", href: "Belgian-Style Ale" },
            { name: "Cider", href: "Cider" },
            { name: "New England / Hazy IPA", href: "New England / Hazy IPA" },
            { name: "Non-Alcoholic", href: "Non-Alcoholic" },
            { name: "Shop All Beer", href: "Shop All Beer" },]
          }
        ],

      },
      {
        name: "Trending",
        href: null,
        sections: [
          {
            sections: [
              { name: "Craft Beer", href: "Craft Beer" },
              { name: "Variety Packs", href: "Variety Packs" },
              { name: "Large Format", href: "Large Format" },
              { name: "Gluten Free / Reduced", href: "Gluten Free / Reduced" },
              { name: "Shop By Brand", href: "Shop By Brand" },
            ]
          }
        ]
      },
      {
        name: "More",
        href: null,
        sections: [
          {
            sections: [
              { name: "Blog", href: "Blog" },
              { name: "Pairing Guides", href: "Pairing Guides" },
              { name: "Buying Guides", href: "Buying Guides" },
            ]
          }
        ]
      },
      {
        name: "Gifting",
        href: null,
        sections: [
          {
            sections: [
              { name: "Beer Gifts", href: "Beer Gifts" },
            ]
          }
        ]
      }

    ]
  },
  */
  {
    name: 'Vinos',
    href: '/vinos',
    sections: [
      {
        name: "Variedad de vinos",
        href: '/vinos',
        sections: [
          {
            sections: [
              {
                name: "Vino Tinto",
                href: '/vino-tinto'
              }
            ]
          },
          {
            sections: [
              {
                name: "Vino Blanco",
                href: "/vino-blanco",
              }

            ]
          },
          {
            sections: [
              {
                name: "Espumantes",
                href: "/espumantes"
              }

            ]
          }
        ],

      },
    ]
  },
  {
    name: 'Licores',
    href: '/licores',
    sections: [
      {
        name: "Tipo de Licor",
        href: '/licores',
        sections: [
          {
            sections: [
              { name: "Whisky", href: "/whiskys" },
            ]
          },
          {
            sections: [
              { name: "Tequila", href: "/tequilas" },
            ]
          },
          {
            sections: [
              { name: "Ron", href: "/rones" },
            ]
          },
          {
            sections: [
              { name: "Vodka", href: "/vodkas" },
            ]
          },
          {
            sections: [
              { name: "Gin", href: "/gins" },
            ]
          },
          {
            sections: [
              { name: "Pisco", href: "/piscos" },
            ]
          },
          {
            sections: [
              { name: "Otros Licores", href: "/licores-cordiales-y-aguardientes" },
            ]
          }
        ],
      },
    ]
  },
  { name: 'Complementos', href: '/complementos' },
  /*
  { name: 'Negocios', href: '/negocios' },
  { name: 'Regalos', href: '/regalos' },
  */
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Header = (props) => {
  const [openMenu, setOpenMenu] = useState(false)
  const [openSearch, setOpenSearch] = useState(false)

  return (
    <nav className="mx-auto bg-white flex h-16 md:h-[68px]  items-center  shadow-lg z-30 sticky top-0 px-4">

      <div className="grow h-full flex flex-1  items-center justify-start ">
        <MenuDrawer open={openMenu} setOpen={setOpenMenu}>
          <div className="flex flex-col pb-4">
            <Link to={'/packs'} onClick={() => { setOpenMenu(false) }} className="bg-red p-4 pl-2 flex items-center border-l-gray-200 hover:border-l-green-400 border-l-8 border-b hover:bg-gray-100 font-bold text-lg" >
              <img className="w-12 h-12 aspect-square" width='120' height='120' src={`https://d2ja6cy9ij2jx1.cloudfront.net/fit-in/160x160/combos.png`} alt={'combos'} />
              <span className="ml-2">Packs</span>
            </Link>
            <Link to={'/whiskys'} onClick={() => { setOpenMenu(false) }} className="bg-red p-4 pl-2 flex items-center border-l-gray-200 hover:border-l-green-400 border-l-8 border-b hover:bg-gray-100 font-bold text-lg" >
              <img className="w-12 h-12 aspect-square" width='120' height='120' src={`https://d2ja6cy9ij2jx1.cloudfront.net/fit-in/160x160/whiskys.png`} alt={'whiskys'} />
              <span className="ml-2">Whiskys</span>
            </Link>
            <Link to={'/tequilas'} onClick={() => { setOpenMenu(false) }} className="bg-red p-4 pl-2 flex items-center border-l-gray-200 hover:border-l-green-400 border-l-8 border-b hover:bg-gray-100 font-bold text-lg" >
              <img className="w-12 h-12 aspect-square" width='120' height='120' src={`https://d2ja6cy9ij2jx1.cloudfront.net/fit-in/160x160/tequilas.png`} alt={'tequilas'} />
              <span className="ml-2">Tequilas</span>
            </Link>
            <Link to={'/rones'} onClick={() => { setOpenMenu(false) }} className="bg-red p-4 pl-2 flex items-center border-l-gray-200 hover:border-l-green-400 border-l-8 border-b hover:bg-gray-100 font-bold text-lg" >
              <img className="w-12 h-12 aspect-square" width='120' height='120' src={`https://d2ja6cy9ij2jx1.cloudfront.net/fit-in/160x160/rones.png`} alt={'rones'} />
              <span className="ml-2">Rones</span>
            </Link>
            <Link to={'/vodkas'} onClick={() => { setOpenMenu(false) }} className="bg-red p-4 pl-2 flex items-center border-l-gray-200 hover:border-l-green-400 border-l-8 border-b hover:bg-gray-100 font-bold text-lg" >
              <img className="w-12 h-12 aspect-square" width='120' height='120' src={`https://d2ja6cy9ij2jx1.cloudfront.net/fit-in/160x160/vodkas.png`} alt={'vodkas'} />
              <span className="ml-2">Vodkas</span>
            </Link>
            <Link to={'/gins'} onClick={() => { setOpenMenu(false) }} className="bg-red p-4 pl-2 flex items-center border-l-gray-200 hover:border-l-green-400 border-l-8 border-b hover:bg-gray-100 font-bold text-lg" >
              <img className="w-12 h-12 aspect-square" width='120' height='120' src={`https://d2ja6cy9ij2jx1.cloudfront.net/fit-in/160x160/gins.png`} alt={'gins'} />
              <span className="ml-2">Gins</span>
            </Link>
            <Link to={'/vinos'} onClick={() => { setOpenMenu(false) }} className="bg-red p-4 pl-2 flex items-center border-l-gray-200 hover:border-l-green-400 border-l-8 border-b hover:bg-gray-100 font-bold text-lg" >
              <img className="w-12 h-12 aspect-square" width='120' height='120' src={`https://d2ja6cy9ij2jx1.cloudfront.net/fit-in/160x160/vinos.png`} alt={'vinos'} />
              <span className="ml-2">Vinos</span>
            </Link>
            <Link to={'/espumantes'} onClick={() => { setOpenMenu(false) }} className="bg-red p-4 pl-2 flex items-center border-l-gray-200 hover:border-l-green-400 border-l-8 border-b hover:bg-gray-100 font-bold text-lg" >
              <img className="w-12 h-12 aspect-square" width='120' height='120' src={`https://d2ja6cy9ij2jx1.cloudfront.net/fit-in/160x160/espumantes.png`} alt={'espumantes'} />
              <span className="ml-2">Espumantes</span>
            </Link>
            <Link to={'/piscos'} onClick={() => { setOpenMenu(false) }} className="bg-red p-4 pl-2 flex items-center border-l-gray-200 hover:border-l-green-400 border-l-8 border-b hover:bg-gray-100 font-bold text-lg" >
              <img className="w-12 h-12 aspect-square" width='120' height='120' src={`https://d2ja6cy9ij2jx1.cloudfront.net/fit-in/160x160/piscos.png`} alt={'piscos'} />
              <span className="ml-2">Piscos</span>
            </Link>
            <Link to={'/licores-cordiales-y-aguardientes'} onClick={() => { setOpenMenu(false) }} className="bg-red p-4 pl-2 flex items-center border-l-gray-200 hover:border-l-green-400 border-l-8 border-b hover:bg-gray-100 font-bold text-lg" >
              <img className="w-12 h-12 aspect-square" width='120' height='120' src={`https://d2ja6cy9ij2jx1.cloudfront.net/fit-in/160x160/licores.png`} alt={'licores'} />
              <span className="ml-2">Otros Licores</span>
            </Link>
            <div className="border-b-2">
            </div>
          </div>
        </MenuDrawer>
        <div className="h-full flex md:hidden justify-center items-center">
          <button name="Menu" aria-label="Menu" type='button' className="rounded-full h-14 w-14 flex items-center justify-center text-center" onClick={() => { setOpenMenu(true) }}>
            <Bars3Icon className="h-7 w-7 text-black" />
          </button>
        </div>
        <div className="hidden md:flex h-full">

          {navigation.map((item, index) => (
            <li key={`${index}-${item.name || item.name}`} className="h-full flex justify-center items-center group ">
              <Link
                key={item.name}
                to={item.href}
                className="relative text-black px-4 font-light text-base"
                aria-current={item.current ? 'page' : undefined}
              >
                {item.name}
                <span className="absolute -bottom-[22px] mx-auto group-hover:w-full w-0 h-[2.8px] left-0 right-0 ease-in-out duration-200 bg-green-500"></span>
              </Link>
              {
                item.sections &&
                <div className="group-hover:absolute group-hover:flex items-start  hidden min-h-[120px] pt-[28px]  pl-[40px] left-0 right-0 top-[68px] bg-gray-50 backdrop-blur-sm shadow-md">
                  {item.sections?.map((item, index) => {
                    return (
                      <section key={`${index}-${item.name || item.name}`}>
                        <Link to={item.href} className="h-8 font-extrabold text-xl leading-none mx-0 my-2" style={{ lineHeight: "0px" }}>{item.name}</Link>
                        <nav className="flex ">
                          {item.sections?.map((item, index) => {
                            return (
                              <div key={`${index}-${item.name || item.name}`} className="flex flex-col mt-3">
                                {
                                  item.sections?.map((item, index) => {
                                    return (
                                      <div className="flex flex-col" key={`${index}-${item.name || item.name}`}>
                                        <Link to={item.href} className="h-10 hover:text-red-500 hover:underline font-normal py-1 leading-8 mr-[2vw]">{item.name}</Link>
                                        {item.sections?.map((item, index) => {
                                          return (
                                            <Link to={item.href} key={`${index}-${item.name || item.name}`} className="h-10 hover:text-red-500 hover:underline font-normal py-1 leading-8 mr-[2vw] pl-3 border-l-[2px] border-l-red-600">{item.name}</Link>
                                          )
                                        })}
                                      </div>
                                    )
                                  })
                                }
                              </div>
                            )
                          })}
                        </nav>
                      </section>
                    )
                  })}
                </div>
              }
            </li>
          ))}
        </div>
      </div>
      <div className="grow-0 h-full min-w-min flex  items-center justify-center">
        <Link className="h-full flex items-center" to='/'>
          <img
            className="h-6 w-auto"
            width={200} height={67}
            src={'https://d2ja6cy9ij2jx1.cloudfront.net/fit-in/200x67/delicores-logo-black.png'}
            alt="Delicores.com"
          />
        </Link>
      </div>
      <div className="grow h-full flex flex-1  items-center justify-end ">
        <Buscador openSearch={openSearch} setOpenSearch={setOpenSearch} />
        <button onClick={() => setOpenSearch(true)} className="ml-4 block md:hidden bg-transparent p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
          <span className="sr-only">Buscador</span>
          <MagnifyingGlassIcon className="w-6 h-6 text-black" aria-hidden="true" />
        </button>
        <CartIcon />
      </div>
    </nav >

  )
}

export default Header