import React from "react"
import { Provider } from "react-redux"
import { createStore, applyMiddleware, compose } from "redux"
import thunk from "redux-thunk"
import rootReducer from "."
import Root from "../../root";

//import { composeWithDevTools } from 'redux-devtools-extension';

const middleware = [thunk]

const loadStorage = () => {
    try {
        const initialStateString = localStorage.getItem("tww")
        if (initialStateString === null) {
            return {}
        }

        return JSON.parse(initialStateString)

    } catch (error) {
        return {}
    }
}

//const initialState = loadStorage()
const initialState = {}

const saveStorage = (state) => {
    try {
        const stateString = JSON.stringify(state);
        localStorage.setItem("tww", stateString)
    } catch (error) {
        console.log(error)
    }
}

const store = createStore(
    rootReducer,
    initialState,
    applyMiddleware(...middleware)
)

/* 
    store.subscribe(() => {
        saveStorage(store.getState())
    }
    )
*/

export default ({ element }) => <Provider store={store}>
    <Root>
        {element}
    </Root>
</Provider>
