import * as React from "react"


const Footer = (props) => {

    return (
        <>
            <div className="relative max-w-md px-4 mx-auto sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
                <div className="relative px-6 py-10 overflow-hidden shadow-xl rounded-3xl bg-gradient-to-r from-blue-800 via-blue-700 to-blue-900  sm:px-12 sm:py-20">

                    <div className="relative">
                        <div className="mb-12 sm:text-center">
                            <h2 className="text-4xl font-extrabold text-white tracking-tight sm:text-4.5xl font-display">
                                Enterate de las novedades y recibe descuentos
                            </h2>
                            <p className="max-w-2xl mx-auto mt-6 text-lg text-cool-indigo-100" >
                                Twice a month I share the best Tailwind templates, UI kits and components in <strong>my newsletter</strong>.<strong>210</strong> Tailwind hackers find it useful.I'd love you to join.
                            </p >
                        </div >
                        <form target="_blank" data-controller="newsletter" data-action="submit->newsletter#onSubmit" data-newsletter-target="form" className="sm:mx-auto sm:max-w-lg sm:flex" action="https://www.getrevue.co/profile/tailwindawesome/add_subscriber" acceptCharset="UTF-8" method="post" ><input type="hidden" name="authenticity_token" value="4_DYEnogKgvReY2JjewNjAgsrPA7irpQ3rI5K2J2VaEIVPQU8A9wWUc74JFrA2lLpnfnvP-23DRszU63KgsEZg" autocomplete="off" />
                            <div className="relative w-full max-w-xl mx-auto bg-white rounded-full h-14 lg:max-w-none" >
                                <input className="rounded-full w-full h-14 bg-transparent py-0 sm:pl-6 pl-5 pr-16 sm:pr-32 outline-none border-2 border-gray-100 shadow-md hover:outline-none focus:ring-cool-indigo-200 focus:border-cool-indigo-200" dataNewsletterTarget="email" required="required" placeholder="Enter your email" autoComplete="email" type="email" name="member[email]" id="member[email]" />
                                <button type="submit" className="absolute inline-flex items-center h-12 p-4 text-sm text-white transition duration-300 ease-in-out rounded-r-full rounded-bl-full outline-none right-1 top-1 bg-cool-indigo-600 sm:py-2 sm:px-6 sm:rounded-full sm:text-base sm:font-medium hover:bg-cool-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cool-indigo-500" >

                                    <svg xmlns="http://www.w3.org/2000/svg" className="-ml-0.5 sm:-ml-1 sm:mr-2 h-5 w-5" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" >
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <line x1="10" y1="14" x2="21" y2="3"></line>
                                        <path d="M21 3l-6.5 18a0.55 .55 0 0 1 -1 0l-3.5 -7l-7 -3.5a0.55 .55 0 0 1 0 -1l18 -6.5"></path>
                                    </svg >
                                    <span className="hidden sm:inline-block" >
                                        Try it
                                    </span >
                                </button >
                            </div >
                        </form >        </div >
                </div >
            </div >
        </>
    )
}


export default Footer

