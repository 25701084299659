import { types } from "../actions/types";

const initialState = {
    open: false,
    loading: false,
    error: false,
    cart: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.OPEN_CART:
            return { ...state, open: true };
        case types.CLOSE_CART:
            return { ...state, open: false };
        default:
            return state;
    }
};