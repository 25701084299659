import React, { useState, useEffect } from "react"
import { TrashIcon } from '@heroicons/react/outline'
import { PlusIcon, MinusIcon } from '@heroicons/react/24/outline'
import { mutate } from 'swr'
import { debounce } from 'lodash';
import { fetchData } from './request';
import toast from 'react-hot-toast';
import axios from "axios"
import api from '../../services/api';
import { formatNumber } from "../../utils/formatNumber";

const Product = ({ product, quantity }) => {

    const [quantityNew, setQuantityNew] = useState(quantity)
    const [searchQuery, setSearchQuery] = useState({});

    const sendQuery = async (product_id, quantity) => {

        const { cancelPrevQuery, result, error } = await fetchData(product_id, quantity);

        if (cancelPrevQuery) return;

        if (!error) {
            //console.log("ACTUALIZACION")
            mutate(`${process.env.GATSBY_API_URL}/carts`)
            toast.success('¡Carrito actualizado!')
            //setDataList(result.data);
            //setErrorMssg('');
        } else {
            //console.log("ERROR")
            toast.error("Ocurrio un error")
            //setDataList([]);
            //setErrorMssg(error);
        }
    };


    const haddleSetToCart = (id, quantity) => {
        setQuantityNew(quantity)
        const search = debounce(sendQuery, 300);
        setSearchQuery(prevSearch => {
            if (prevSearch.cancel) {
                prevSearch.cancel();
            }
            return search;
        });

        search(id, quantity);
    }

    const haddleDeleteItem = (id) => {
        api.post(`${process.env.GATSBY_API_URL}/carts/delete-product`, { product_id: id })
            .then(() => {
                mutate(`${process.env.GATSBY_API_URL}/carts`)
                toast.success('¡Carrito actualizado!')
            })
            .catch((error) => {
                toast.error("Ocurrio un error")
            })
    }

    const pathImage = product.image ? new URL(product.image) : { pathname: 'default-product.png' }

    return (
        <>
            <li key={product._id} className="flex py-6">
                <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                    <img
                        src={`https://d2ja6cy9ij2jx1.cloudfront.net/fit-in/256x256${pathImage.pathname}`}
                        alt={product.imageAlt}
                        className="h-full w-full object-cover object-center"
                    />
                </div>

                <div className="ml-4 flex flex-1 flex-col">
                    <div>
                        <div className="flex justify-between text-base font-medium text-gray-900">
                            <h3>
                                <a href={product.href}>{product.name}</a>
                            </h3>
                            <div className="ml-4 flex">S/{formatNumber(product.price, 2)}</div>
                        </div>
                        <p className="mt-1 text-sm text-gray-500">{product.color}</p>
                    </div>
                    <div className="flex flex-1 items-end justify-between text-sm">
                        <div className="flex items-center justify-center border rounded-lg">
                            <button className="w-8 h-8 flex justify-center items-center rounded-l-lg hover:bg-gray-100" onClick={() => { return quantityNew > 1 ? haddleSetToCart(product._id || product.id, quantityNew - 1) : () => { } }}>
                                <MinusIcon className="w-5 h-5" />
                            </button>
                            <div className="w-10 text-center">
                                {quantityNew}
                            </div>
                            <button className="w-8 h-8 flex justify-center items-center rounded-r-lg hover:bg-gray-100" onClick={() => { haddleSetToCart(product._id || product.id, quantityNew + 1) }}>
                                <PlusIcon className="w-5 h-5" />
                            </button>
                        </div>
                        <div className="flex">
                            <button onClick={() => { haddleDeleteItem(product._id || product.id) }} type="button" className="font-medium text-red-600 hover:text-red-500">
                                Quitar
                            </button>
                        </div>
                    </div>
                </div>
            </li>
        </>
    )
}


export default (Product)


