/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import Header from "./Header"
import Footer from "./Footer"
import SuscriptionForm from './SuscriptionForm'
import { Toaster } from 'react-hot-toast';
import ShoppingCart from "./ShoppingCart"
import CountDownMin from './CountDownMin'
import Stories from './Stories';



const Layout = ({ children, ...props }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  console.log("props.location")
  console.log(props.location)

  if (props.location.pathname === "/landing/" || props.location.pathname === "/landing") {
    return <>
      {children}
    </>
  }

  return (
    <>
      {/*
      <Link to='/liquidacion' className="leading-none flex justify-center items-center py-2 h-10 text-white font-bold bg-gradient-to-r from-indigo-600 via-purple-600 to-pink-600" >
        <div className="flex items-center justify-center">
          <img src='https://lamagiacuraelcancer.com/assets/img/asociacion_voluntarias_ninos_cancer_peru_logo2.png' className="mr-2" width={"54px"} height={"28.8px"} />
          <div align="center"> 📢 CYBER LICORES ⏰</div>
          <div><CountDownMin date='2023/07/07 23:59:59' /></div>
          <div className="hidden md:flex text-white rounded-full px-2 ml-3 text-sm bg-white bg-opacity-20"> DONAR</div>
          
        </div>
      </Link>
      */}


      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />

      {/*
      <button type="button">
        promociones

      </button>
      <Stories />
    */}
      <main className="min-h-screen w-full bg-gray-50">{children}</main>
      <a href={"https://wa.me/51975711779"} rel="noreferrer" className="fixed bottom-4 hover:scale-105 right-0 rounded-l-full p-[12px] pl-[18px]  bg-green-500 z-10 text-white shadow-2xl" style={{
        "WebkitBoxShadow": "0px 0px 10px 7px rgba(0,0,0,0.30)",
        "MozBoxShadow": "0px 0px 10px 7px rgba(0,0,0,0.30)",
        "boxShadow": "0px 0px 10px 7px rgba(0,0,0,0.30)",
      }}>
        <svg className='w-9 h-9  text-white' fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>WhatsApp icon</title><path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413Z" /></svg>
      </a>
      <Footer />
      <ShoppingCart />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
