import React from 'react'
import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { PaperAirplaneIcon, ShoppingCartIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { connect } from "react-redux"
import ProductCart from '../ProductCart'
import { openCart, closeCart } from "../../actions/cart"
import useSWR from "swr";
import axios from 'axios'
import { navigate } from 'gatsby'
import api from '../../services/api';
import { formatNumber } from '../../utils/formatNumber'
import { Formik } from 'formik'
import * as Yup from 'yup';
import { mutate } from 'swr'
import { LoaderIcon } from 'react-hot-toast'
/*
const fetcher = (url) => fetch(url, {
    method: 'GET',
    credentials: 'include',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
}).then((res) => res.json());
*/
const fetcher = url => api.post(url).then(res => res.data)

const ShoppingCart = ({ handleCloseCart, cart, ...res }) => {

    const [subTotal, setSubTotal] = useState(0)
    const { data, error } = useSWR(`${process.env.GATSBY_API_URL}/carts`, fetcher)

    var carrito = ""

    if (error) {
        carrito = <div className="font-bolf text-lg"> Hubo un error al obtener los datos, si el error persiste comuniquese por atención al cliente</div>
    }

    if (!data) {
        carrito = <div className="font-bolf text-lg"> loading...</div>
    }

    var subTotalTemp = 0

    if (data && data.data) {

        if (Boolean(data.data.products.length > 0)) {
            data.data.products.forEach(element => {
                subTotalTemp = subTotalTemp + (element.quantity * element.product.price)
            });

            carrito = data.data.products.map(element => {
                return <ProductCart key={element.product?._id || element.product?.id} product={element.product} quantity={element.quantity} />
            });
        }
        else {
            carrito = <div className="font-bolf text-lg h-full flex flex-col justify-center items-center my-auto">
                <div className="font-bold text-3xl text-center mt-32">
                    <img
                        src={`https://d2ja6cy9ij2jx1.cloudfront.net/fit-in/200x200/icon-cart.png`}
                        alt="Carrito Vacio"
                        className="h-28 w-28 object-cover object-center"
                    />
                </div>
                <p className="text-center font-thin text-lg mt-4">
                    Aun no agregas productos
                </p>
            </div>
        }

    }

    const handleRemoveCoupon = () => {
        api.post(
            `${process.env.GATSBY_API_URL}/carts/remove-discount-code`,
            {}, {
            withCredentials: true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
        )
            .then(response => {
                mutate(`${process.env.GATSBY_API_URL}/carts`)
                //setOpen(false)
                //toast.success(response.data.message)
            })
            .catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        setSubTotal(subTotalTemp)
    }, [data]);


    return (
        <>
            <Transition.Root show={cart.open} as={Fragment}>
                <Dialog as="div" className="relative z-30" onClose={handleCloseCart}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-hidden">
                        <div className="absolute inset-0 overflow-hidden">
                            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                                <Transition.Child
                                    as={Fragment}
                                    enter="transform transition ease-in-out duration-300 sm:duration-300"
                                    enterFrom="translate-x-full"
                                    enterTo="translate-x-0"
                                    leave="transform transition ease-in-out duration-300 sm:duration-300"
                                    leaveFrom="translate-x-0"
                                    leaveTo="translate-x-full"
                                >
                                    <Dialog.Panel className="pointer-events-auto w-screen max-w-md ">
                                        <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                                            <div className="flex-1 overflow-y-auto py-4 px-4 h-full ">
                                                <div className="flex items-start justify-between">
                                                    <Dialog.Title className="text-lg font-medium text-gray-900">Productos Seleccionados</Dialog.Title>
                                                    <div className="ml-3 flex h-7 items-center">
                                                        <button
                                                            type="button"
                                                            className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                                                            onClick={handleCloseCart}
                                                        >
                                                            <span className="sr-only">Close panel</span>
                                                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="">
                                                    <div className="flow-root">
                                                        <ul role="list" className="divide-y divide-gray-200 h-full relative ">
                                                            <>
                                                                {carrito}
                                                            </>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            {/*
                                            <div className='flex px-4 sm:px-6 py-3 border-t'>
                                                <input type="text" className='form-input flex-1 rounded-l-lg h-10 border' placeholder='Código de descuento' />
                                                <button className='h-10 w-10 bg-slate-700 flex justify-center items-center text-white rounded-r-lg'><PaperAirplaneIcon className='w-5 h-5' /></button>
                                            </div>
                                            */}

                                            <div className="border-t border-gray-200 pt-2">
                                                <div className="flex justify-between text-base font-bold text-gray-900 px-4">
                                                    <p>Total</p>
                                                    <p>S/{formatNumber(subTotal, 2)}</p>
                                                </div>

                                                <p className="mt-0.5 text-xs font-thin text-gray-500 px-4">*Los costo de envío aun no estan calculados</p>
                                                <div className="mt-3">
                                                    {
                                                        Boolean(data?.data?.products?.length > 0) ?
                                                            <button
                                                                onClick={() => { navigate('/cart'); handleCloseCart() }}
                                                                className="flex w-full items-center justify-center border border-transparent bg-green-500 px-6 py-4 text-base font-medium text-white shadow-sm hover:bg-green-600"
                                                            >
                                                                Ir al Carrito
                                                            </button> :
                                                            <button
                                                                onClick={handleCloseCart}
                                                                className="flex w-full items-center justify-center border border-transparent bg-green-500 px-6 py-4 text-base font-medium text-white shadow-sm hover:bg-green-600"
                                                            >
                                                                Comenzar a comprar
                                                            </button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )
}

const mapStateToProps = state => ({
    cart: state.cart,
})

const mapDispatchToProps = dispatch => {
    return {
        handleCloseCart: () => dispatch({ type: `CLOSE_CART` }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCart)
