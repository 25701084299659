import React, { useState, useEffect } from 'react';
import 'moment/locale/es'
import moment from 'moment';

function CountDown({ date, ...res }) {

    moment.locale('es')

    const calculateTimeLeft = () => {
        let year = new Date().getFullYear();
        const difference = +new Date(date) - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    const [year] = useState(new Date().getFullYear());

    useEffect(() => {
        setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);
    });

    const timerComponents = [];

    Object.keys(timeLeft).forEach((interval) => {
        if (!timeLeft[interval]) {
            return;
        }

        timerComponents.push(
            <span>
                {timeLeft[interval]} {interval}{" "}
            </span>
        );
    });


    return (
        <>
            <div className="flex justify-center ml-2">
                {timerComponents.length ?
                    <div className="flex space-x-1 sm:space-x-2">
                        <div className="inline-block py-2" align='center'>
                            <div className="bg-gray-900 bg-opacity-70 rounded-md p-1 rounded-t-md">
                                <div className="text-md sm:text-2xl md:text-lg  text-center text-white font-bold" style={{ lineHeight: "115%" }}>
                                    {timeLeft.days > 9 ? timeLeft.days : '0' + timeLeft.days}d

                                </div>
                            </div>
                        </div>
                        <div className="inline-block py-2" align='center'>
                            <div className="bg-gray-900 bg-opacity-70 rounded-md p-1 rounded-t-md">
                                <div className="text-md sm:text-2xl md:text-lg  text-center text-white font-bold" style={{ lineHeight: "115%" }}>
                                    {timeLeft.hours > 9 ? timeLeft.hours : '0' + timeLeft.hours}h

                                </div>
                            </div>
                        </div>
                        <div className="inline-block py-2" align='center'>
                            <div className="bg-gray-900 bg-opacity-70 rounded-md p-1 rounded-t-md">
                                <div className="text-md sm:text-2xl md:text-lg  text-center text-white font-bold" style={{ lineHeight: "115%" }}>
                                    {timeLeft.minutes > 9 ? timeLeft.minutes : '0' + timeLeft.minutes}m
                                </div>
                            </div>
                        </div>
                        <div className="inline-block py-2" align='center'>
                            <div className="bg-gray-900 bg-opacity-70 rounded-md p-1 rounded-t-md">
                                <div className="text-md sm:text-2xl md:text-lg  text-center text-white font-bold" style={{ lineHeight: "115%" }}>
                                    {timeLeft.seconds > 9 ? timeLeft.seconds : '0' + timeLeft.seconds}s
                                </div>
                            </div>
                        </div>
                    </div >
                    : <span></span>
                }
            </div>
        </>
    );
}


export default CountDown;
