import React, { useState, useRef, useEffect } from "react"
import classnames from 'classnames'
import { debounce } from 'lodash';
import { fetchData } from './request';
import ListProductItemBuscador from './ListProductItemBuscador'
import { ArrowLeftIcon, XMarkIcon } from "@heroicons/react/24/outline";

const Buscador = (props) => {

    const ref = useRef();
    const inputRef = useRef();
    const inputBarRef = useRef();

    const { openSearch = false, setOpenSearch } = props;

    var classModal = classnames({
        "hidden sm:block transition ease-out duration-0  transform opacity-0 scale-95": !openSearch,
        "hidden sm:block transition ease-in duration-0 transform opacity-100 scale-100": openSearch,
    })

    useOnClickOutside(ref, () => { setOpenSearch(false) });

    const [query, setQuery] = useState('');
    const [searchQuery, setSearchQuery] = useState({});
    const [dataList, setDataList] = useState([]);
    const [errorMssg, setErrorMssg] = useState('');


    const onChange = ({ target: { value } }) => {
        setQuery(value);

        const search = debounce(sendQuery, 200);

        setSearchQuery(prevSearch => {
            if (prevSearch.cancel) {
                prevSearch.cancel();
            }
            return search;
        });

        search(value);
    };

    const sendQuery = async value => {
        setDataList([]);
        const { cancelPrevQuery, result, error } = await fetchData(value);

        if (cancelPrevQuery) return;

        if (!error) {
            setDataList(result.data);
            setErrorMssg('');
        } else {
            setDataList([]);
            setErrorMssg(error);
        }
    };

    return (
        <div ref={ref} className="relative flex items-center max-w-sm h-full w-full mx-4">
            <div className="relative text-left ml-5 w-full ">
                <div className="hidden md:flex  mx-auto rounded-sm overflow-hidden  w-full items-center" onClick={() => setOpenSearch(true)}>
                    <div className="flex bg-gray-100 rounded-full h-9 w-full px-2 group">
                        <input ref={inputBarRef} value={query} onChange={onChange}
                            type="text" className="h-9  mr-2 w-full hover:cursor-pointer bg-transparent appearance-none focus:ring-0 border-0 border-gray-300 focus:outline-none" />
                        <button className="text-gray-800 hover:text-gray-600 group-hover:text-gray-600 focus:ring-0 focus:outline-none" >
                            <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                            </svg>
                        </button>
                    </div>
                </div>
                {openSearch &&
                    <div className={classModal}>
                        <div className="origin-top-left absolute w-full right-0 mt-4 #w-96 overflow-y-auto shadow-lg bg-white ring-1 py-1 px-1 ring-gray-400 " style={{ maxHeight: 400 }}>
                            <div className="py-1 w-full space-y-2" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                {
                                    dataList.length <= 0 && <div className='flex justify-center font-bold py-4 w-full'>
                                        Sin resultados
                                    </div>
                                }
                                <ListProductItemBuscador products={dataList} setOpenSearch={(value) => setOpenSearch(value)} />
                            </div>
                        </div>
                    </div>
                }
            </div>
            {openSearch &&
                <div className="md:hidden fixed inset-0 z-30 w-screen h-screen">
                    <div className="bg-gray-100 flex flex-col h-full w-full ">
                        {/* 
                        <div className="w-full bg-white">
                            <button className="absolute top-3 right-3 text-white" onClick={() => setOpenSearch(false)}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </button>
                            <div className="flex  sm:h-16 h-14 w-full px-4 items-center border-b shadow-lg text-white ">
                                <button className="" onClick={() => setQuery('')}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2M3 12l6.414 6.414a2 2 0 001.414.586H19a2 2 0 002-2V7a2 2 0 00-2-2h-8.172a2 2 0 00-1.414.586L3 12z" />
                                    </svg>
                                </button>
                                <input className="text-white" ref={inputRef} placeholder="Buscador..." value={query} onChange={onChange}
                                    type="text" name="q" className="text-lg font-bold hover:cursor-pointer bg-transparent appearance-none focus:ring-0 border-0 focus:outline-none text-white placeholder-gray-100 placeholder-opacity-80 " name="" />
                                <div>
                                </div>
                            </div>
                        </div>
                        */}
                        <div className="w-full bg-white flex justify-center items-center space-x-2 px-4 py-2 h-16">
                            <div className="" onClick={() => setOpenSearch(false)}>
                                <ArrowLeftIcon className="w-7 h-7 text-slate-500" />
                            </div>
                            <div className="relative w-full max-w-md border border-slate-300 rounded-full shadow-sm flex items-center h-10">
                                <input ref={inputRef} value={query} onChange={onChange} placeholder='Busca tus licores favorito' className="w-full block bg-transparent py-2 pl-6 pr-3  focus:outline-none border-0 focus:ring-0 sm:text-sm" type="text" name="search" />
                                <div className='flex items-center'>
                                    <button onClick={(e) => onChange({ target: { value: '' } })} type='button' className='w-10 h-10  flex justify-center items-center px-2 mr-4'>
                                        <XMarkIcon className="h-5 w-5 text-slate-500" />
                                    </button>
                                    {/*
                                    <span className='border-l border-solid h-6'></span>
                                    <button type='button' className='w-10 h-10  flex justify-center items-center  pl-2 pr-3'>
                                        <SearchIcon className="h-5 w-5 text-sky-500" />
                                    </button>
                                    */}
                                </div>
                            </div>
                        </div>
                        <div className="py-1 overflow-y-auto flex-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                            {
                                (Array.isArray(dataList.length) && dataList.length <= 0) && <div className='flex h-full w-full justify-center font-bold items-center'>
                                    Sin resultados
                                </div>
                            }
                            <ListProductItemBuscador products={dataList} setOpenSearch={(value) => setOpenSearch(value)} />
                        </div>
                    </div>

                </div>
            }
        </div>
    )

}

const useOnClickOutside = (ref, handler) => {
    useEffect(
        () => {
            const listener = event => {
                if (!ref.current || ref.current.contains(event.target)) {
                    return;
                }
                handler(event);
            };

            document.addEventListener('mousedown', listener);
            document.addEventListener('touchstart', listener);

            return () => {
                document.removeEventListener('mousedown', listener);
                document.removeEventListener('touchstart', listener);
            };
        },
        [ref, handler]
    );
}

export default Buscador
