import React from "react";
import { Location, globalHistory } from "@reach/router";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { ReachAdapter } from 'use-query-params/adapters/reach';
import { parse, stringify } from 'query-string';

export default ({ children }) => (
    <Location>
        {({ location }) => (
            <QueryParamProvider location={location} adapter={ReachAdapter} reachHistory={globalHistory}>
                {children}
            </QueryParamProvider>
        )}
    </Location>
);