import axios from 'axios';
import api from '../../services/api';
const API_KEY = 'api_key'

let tokenSource;
export const fetchData = async keyword => {
  try {
    if (typeof tokenSource !== typeof undefined) {
      tokenSource.cancel('Operation canceled due to new request.');
    }

    // save the new request for cancellation
    tokenSource = axios.CancelToken.source();

    const { data } = await api.get(`${process.env.GATSBY_API_URL_FIND}/products/search/${keyword}`, {
      cancelToken: tokenSource.token
    });

    return { result: data };

  } catch (err) {
    if (axios.isCancel(err)) return { cancelPrevQuery: true };
    //return [err];
    return { error: err };
  }
};