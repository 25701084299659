import { types } from "../actions/types";

const initialState = {
    open: false,
    loading: false,
    error: false,
    product: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.OPEN_PREVIEW:
            return { ...state, open: true };
        case types.CLOSE_PREVIEW:
            return { ...state, open: false };
        case types.LOADING_PREVIEW:
            return { ...state, open: true, error: false, product: {} };
        case types.ERROR_PREVIEW:
            return { ...state, loading: false, error: true, product: {} };
        case types.SUCCESS_PREVIEW:
            return { ...state, loading: false, error: false, product: action.product };
        default:
            return state;
    }
};