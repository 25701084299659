import { combineReducers } from 'redux';
import app from './app';
import cart from './cart';
import preview from './preview';
import auth from './auth';

export default combineReducers({
    data: app,
    cart: cart,
    preview: preview,
    auth: auth
})