module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"DeLicores.com | Licorería Delivery | Licores al por mayor y menor","short_name":"DeLicores.com","description":"DeLicores.com, Licorería Delivery 24 horas, Licores con garantia y seguridad, las mayor variedad y ofertas de licores al mejor precio, delivery de licores en Lima las 24 horas. ¡Pide Ya!.","start_url":"/","background_color":"#ffffff","theme_color":"#000000","display":"minimal-ui","icon":"src/images/favicon.svg","icons":[{"src":"src/images/favicon.svg","sizes":"192x192","type":"image/svg"},{"src":"src/images/favicon.svg","sizes":"512x512","type":"image/svg"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"26eca765d33de4bf0dc0d5400045cd39"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/components/layout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KFSWM9ZV","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
