import * as React from "react"
import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ChevronLeftIcon, XMarkIcon } from '@heroicons/react/24/outline'

const MenuDrawer = (props) => {

    const { open = false, setOpen, children } = props;
    //const [open, setOpen] = useState(true)

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="nav" className="relative z-30" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-full  pr-10">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-300 sm:duration-300"
                                enterFrom="-translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-300 sm:duration-300"
                                leaveFrom="translate-x-0"
                                leaveTo="-translate-x-full"
                            >
                                <Dialog.Panel className="pointer-events-auto relative w-screen">
                                    <div className="flex h-full flex-col overflow-y-scroll bg-white  shadow-xl">
                                        <div className="flex flex-none items-center border-b h-[60px] ">
                                            <nav className="p-1 flex-1">
                                                <button type="button" className="flex items-center justify-center text-center rounded-full h-12 w-12 hover:bg-gray-50 mr-2" onClick={() => { setOpen(false) }}>
                                                    {/*
                                                <ChevronLeftIcon className="h-5 w-5 mx-auto my-auto text-black m-0" />
                                            */}
                                                    <span className="flex justify-center items-center">
                                                        <XMarkIcon className="h-6 w-6 mx-auto my-auto text-black m-0 text-lg stroke-2" />
                                                    </span>
                                                </button>
                                            </nav>
                                            <nav className="p-1 flex-1 justify-around items-center ">
                                                <ul className="flex justify-center items-center">
                                                    <li className="inline-block">
                                                        <p className="text-lg text-center block font-medium text-gray-900">MENU</p>
                                                    </li>
                                                </ul>
                                            </nav>
                                            <div className="p-1 flex-1 ">

                                            </div>
                                        </div>
                                        <div className="relative mt flex-1">
                                            {/* Replace with your content */}
                                            <div className="absolute inset-0 pt-2">
                                                {children}
                                            </div>
                                            {/* /End replace */}
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default MenuDrawer